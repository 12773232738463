//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { doctorsIllustration } from "@debionetwork/ui-icons";
export default {
  name: "modalBounty",
  props: {
    title: {
      type: String,
      default: ""
    },
    link: {
      type: [String, Object],
      default: null
    },
    subTitle: {
      type: String,
      default: null
    },
    show: Boolean,
    loading: Boolean
  },
  data: () => ({
    doctorsIllustration
  }),
  computed: {
    computeModalText() {
      return this.loading ? "It takes a while, please do not refresh the page" : this.title;
    }
  }
};