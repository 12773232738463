import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { layersIcon, noteIllustration, medicalResearchIllustration, cardGradient } from "@debionetwork/ui-icons";
import StakingServiceTab from "./StakingServiceTab.vue";
import modalBounty from "./modalBounty.vue";
import { mapState } from "vuex";
import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import localStorage from "@/common/lib/local-storage";
import { u8aToHex } from "@polkadot/util";
import { syncDecryptedFromIPFS } from "@/common/lib/ipfs";
import ConfirmationDialog from "@/common/components/Dialog/ConfirmationDialog";
import { createSyncEvent, getCategories, getOrderList } from "@/common/lib/api";
import { queryDnaSamples, queryDnaTestResults, unstakeRequest, unstakeRequestFee, retrieveUnstakedAmount } from "@debionetwork/polkadot-provider";
import { getDNACollectionProcess } from "@/common/lib/api";
import { ORDER_STATUS_DETAIL } from "@/common/constants/status";
import PaymentDialog from "@/common/components/Dialog/PaymentDialog";
export default {
  name: "MyTest",
  components: {
    StakingServiceTab,
    modalBounty,
    ConfirmationDialog,
    PaymentDialog
  },
  data: () => ({
    layersIcon,
    noteIllustration,
    cardBlock: false,
    isSuccessBounty: false,
    isShowModalBounty: false,
    modalBountyLoading: false,
    isBountyError: null,
    selectedBounty: null,
    publicKey: null,
    secretKey: null,
    tabs: null,
    isBounty: false,
    isLoading: false,
    showDialog: false,
    showRetrieveDialog: false,
    medicalResearchIllustration,
    isLoding: false,
    isLoadingData: false,
    loadingDialog: false,
    txWeight: 0,
    testList: [],
    error: null,
    cardGradient,
    headers: [{
      text: "Service Name",
      value: "serviceName",
      sortable: true
    }, {
      text: "Lab Name",
      value: "labName",
      sortable: true
    }, {
      text: "Order Date",
      value: "orderDate",
      sortable: true
    }, {
      text: "Last Update",
      value: "updatedAt",
      sortable: true
    }, {
      text: "Test Status",
      value: "orderStatus",
      width: "115",
      sortable: true
    }, {
      text: "Actions",
      value: "actions",
      sortable: false,
      align: "center"
    }]
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData,
      mnemonicData: state => state.substrate.mnemonicData,
      stakingId: state => state.lab.stakingId,
      web3: state => state.metamask.web3
    }),
    userAddress() {
      return JSON.parse(localStorage.getKeystore())["Address"];
    },
    computeModalTitle() {
      const title = this.isSuccessBounty ? "Great! Your data has been placed on marketplace successfully!" : "Do you want to add your test result as a data bounty?";
      return this.isBountyError ? this.isBountyError : title;
    },
    computeModalSubtitle() {
      const subtitle = this.isSuccessBounty ? "Congratulations! You get XX $DBIO as reward!" : "You can learn more about data bounty by seeing the information";
      return this.isBountyError ? "Something went wrong, please try again" : subtitle;
    },
    computeModalLink() {
      return this.isSuccessBounty || this.isBountyError ? null : "/";
    }
  },
  watch: {
    lastEventData(event) {
      if (event === null) return;
      const dataEvent = JSON.parse(event.data.toString());
      if (event.method === "DataStaked") {
        this.$store.dispatch("substrate/addAnyNotification", {
          address: this.wallet.address,
          dataAdd: {
            message: "Great! Your data has been placed on ocean marketplace successfully! You have recieve xx DBIO",
            data: dataEvent,
            route: "customer-data-bounty",
            params: null
          },
          role: "customer"
        });
      }
    },
    mnemonicData(val) {
      if (val) this.initialData();
    }
  },
  async mounted() {
    if (this.$route.params.page) {
      this.tabs = /^[0-1]*$/.test(parseInt(this.$route.params.page)) ? parseInt(this.$route.params.page) : 0;
    }
    await this.fetchOrderList();
    const txWeight = await unstakeRequestFee(this.api, this.wallet, this.stakingId);
    this.txWeight = this.web3.utils.fromWei(String(txWeight.partialFee), "ether");
  },
  async created() {
    if (this.mnemonicData) await this.initialData();
  },
  methods: {
    formatDate(date) {
      const formattedDate = new Date(parseInt(date.replace(/,/g, ""))).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      });
      return formattedDate;
    },
    async fetchOrderList() {
      this.isLoadingData = true;
      try {
        const result = await getOrderList();
        const orderList = result.orders.data;
        if (!orderList.length) {
          this.isLoadingData = false;
          return;
        }
        const newList = orderList.filter(order => order._source.status !== "Unpaid" && order._source.status !== "Cancelled");
        newList.forEach(async order => {
          const {
            id: orderId,
            lab_info: {
              name: labName
            },
            service_info: {
              name: serviceName,
              image: serviceImage,
              dna_collection_process: dnaCollectionProcess,
              long_description: description
            },
            dna_sample_tracking_id: dnaSampleTrackingId,
            created_at: createdAt,
            status: paymentStatus
          } = order._source;
          const dnaSample = await queryDnaSamples(this.api, dnaSampleTrackingId);
          let dnaTestResults;
          if (paymentStatus === "Fulfilled") {
            dnaTestResults = await queryDnaTestResults(this.api, dnaSampleTrackingId);
          }
          const orderDetail = {
            orderId,
            dnaSampleTrackingId,
            labName,
            serviceName,
            serviceImage,
            orderDate: this.formatDate(createdAt),
            updatedAt: this.formatDate(dnaSample.updatedAt),
            orderStatus: dnaSample.status,
            paymentStatus,
            dnaCollectionProcess,
            description,
            dnaTestResults,
            timestamp: new Date(parseInt(dnaSample.updatedAt.replaceAll(",", ""))).getTime().toString()
          };
          this.testList.push(orderDetail);
          this.testList.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));
          this.isLoadingData = false;
        });
      } catch (error) {
        this.isLoadingData = false;
      }
    },
    async initialData() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(cred.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(cred.boxKeyPair.secretKey);
    },
    setStatusColor(status) {
      if (status === "Rejected") {
        const detail = ORDER_STATUS_DETAIL[status.toUpperCase()];
        return detail().color;
      }
      return ORDER_STATUS_DETAIL[status.toUpperCase()].color;
    },
    setTestStatus(status) {
      if (status === "Rejected") {
        const detail = ORDER_STATUS_DETAIL[status.toUpperCase()];
        return detail().name;
      }
      return ORDER_STATUS_DETAIL[status.toUpperCase()].name;
    },
    setServiceImage(image) {
      return image ? image : require("@/assets/debio-logo.png");
    },
    goToDetail(id) {
      this.$router.push({
        name: "order-history-detail",
        params: {
          id
        }
      });
    },
    async goToInstruction(item) {
      if (item.description.split("||").length > 1) {
        window.open(item.description.split("||")[1], "_blank");
        return;
      }
      const dnaCollectionProcess = await getDNACollectionProcess();
      const link = dnaCollectionProcess.filter(e => e.collectionProcess === item.dnaCollectionProcess)[0].link;
      window.open(link, "_blank");
    },
    async handleSelectedBounty(val) {
      this.selectedBounty = {
        ...val.dnaTestResults,
        ...val
      };
      this.isShowModalBounty = true;
    },
    handleSuccessBounty() {
      this.isShowModalBounty = false;
      setTimeout(() => {
        this.isSuccessBounty = false;
      }, 350);
    },
    async downloadFile() {
      if (!this.selectedBounty) return;
      this.modalBountyLoading = true;
      try {
        var _this$selectedBounty, _this$selectedBounty2, _this$selectedBounty3;
        const pair = {
          secretKey: this.secretKey,
          publicKey: this.publicKey
        };
        await syncDecryptedFromIPFS(this.selectedBounty.resultLink.replace("https://ipfs.io/ipfs/", ""), pair, "".concat((_this$selectedBounty = this.selectedBounty) === null || _this$selectedBounty === void 0 ? void 0 : _this$selectedBounty.trackingId, ".vcf"), "text/vCard");
        const serviceCategories = await getCategories();
        const service = serviceCategories.find(service => service.name === this.selectedBounty.serviceInfo.category);
        await createSyncEvent({
          orderId: (_this$selectedBounty2 = this.selectedBounty) === null || _this$selectedBounty2 === void 0 ? void 0 : _this$selectedBounty2.orderId,
          serviceCategoryId: service.id,
          fileName: "".concat((_this$selectedBounty3 = this.selectedBounty) === null || _this$selectedBounty3 === void 0 ? void 0 : _this$selectedBounty3.trackingId, ".vcf")
        });
        this.selectedBounty = null;
        this.isSuccessBounty = true;
        this.modalBountyLoading = false;
      } catch (e) {
        this.isBountyError = e === null || e === void 0 ? void 0 : e.message;
        this.modalBountyLoading = false;
      }
    },
    cancelBounty() {
      this.isBounty = false;
    },
    async unstakeService() {
      const requestId = this.stakingId;
      this.isLoading = true;
      await unstakeRequest(this.api, this.wallet, requestId, this.fetchStakingTab);
    },
    async retrieveService() {
      const requestId = this.stakingId;
      this.isLoading = true;
      await retrieveUnstakedAmount(this.api, this.wallet, requestId, this.fetchStakingTab // eslint-disable-next-line no-unused-vars
      );
    },

    fetchStakingTab() {
      this.$refs.staking.fetchData();
      this.isLoading = false;
      this.showDialog = false;
      this.showRetrieveDialog = false;
    },
    showError() {
      this.error = {
        title: "Error",
        message: "No Service Available"
      };
    }
  }
};